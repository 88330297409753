<template>
  <svg
    width="30"
    height="14"
    viewBox="0 0 30 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="2" fill="currentColor" />
    <rect y="6" width="30" height="2" fill="currentColor" />
    <rect y="12" width="30" height="2" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: "CustomMenuIcon",
};
</script>
